import { Application } from "@hotwired/stimulus";
import "@hotwired/turbo-rails";
import "../confirm";
import { registerControllers } from "../lib/stimulus_register";

// stimulus
const application = Application.start();
application.warnings = false;
application.debug = false;

registerControllers(
  application,
  import.meta.glob(
    "../../../app/components/legacy_ui/**/*_controller.{js,ts}",
    { eager: true },
  ),
);
registerControllers(
  application,
  import.meta.glob("../../../app/components/salon/**/*_controller.{js,ts}", {
    eager: true,
  }),
);
registerControllers(
  application,
  import.meta.glob("../../../app/components/hairbook/**/*_controller.{js,ts}", {
    eager: true,
  }),
);
registerControllers(
  application,
  import.meta.glob("../stimulus/*_controller.{js,ts}", {
    eager: true,
  }),
);

document.addEventListener("turbo:submit-start", function () {
  document.querySelector("html").ariaBusy = true;
});

document.addEventListener("turbo:submit-end", function () {
  document.querySelector("html").ariaBusy = false;
});

window.onload = function () {
  if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
    document.body.addEventListener(
      "touchstart",
      function () {
        return true;
      },
      false,
    );
  }
};
